@font-face {
  font-family: Neue;
  src: url('./assets/fonts/NeueHaasDisplayRoman.ttf');
}

@font-face {
  font-family: Neue-light;
  src: url('./assets/fonts/NeueHaasDisplayThin.ttf');
}

.App {
  font-family: Neue;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: .5px;

  &.hidden {
    display: none;
  }
  
  & * {
    box-sizing: border-box;
  }

}

.app-container {
  width: fit-content;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 720px) {
    overflow: initial;
    padding-top: 100px;
    width: 100%;
    display: block;
  }
}
